import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-plugin-intl"
import { Typography } from "@material-ui/core"
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"

const useStyles = makeStyles(theme => ({
  categoryHeader: {
    width: "100%",
    display: "flex",
    marginBottom: 20,
  },
  categoryName: {
    flexGrow: 1,
  },
  categoryLink: {
    flexGrow: 1,
    color: theme.palette.common.black,
    lineHeight: "48px",
    textDecoration: "none",
    "& svg": {
      marginBottom: "-6px",
    },
  },
}))

const ListHeading = props => {
  const { title, link, linkTitle } = props
  const classes = useStyles()

  if (!link) {
    return (
      <div className={classes.categoryHeader}>
        <Typography variant="h2" className={classes.categoryName}>
          {title}
        </Typography>
      </div>
    )
  } else {
    return (
      <div className={classes.categoryHeader}>
        <Typography variant="h2" className={classes.categoryName}>
          <Link className={classes.categoryLink} to={link}>
            {title}
          </Link>
        </Typography>
        <Typography variant="h4">
          <Link className={classes.categoryLink} to={link}>
            {linkTitle}
            <ArrowRightAltIcon />
          </Link>
        </Typography>
      </div>
    )
  }
}

export default ListHeading
