import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/header/seo"
import { makeStyles } from "@material-ui/core/styles"
import { injectIntl } from "gatsby-plugin-intl"
import PageIntro from "../components/fragment/pageIntro"
import ListHeading from "../components/fragment/listHeading"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "50px 20px 50px",
    [theme.breakpoints.up("sm")]: {
      padding: "100px 50px 50px",
    },
  },
}))

const CategoriesPage = props => {
  const { data, intl } = props
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Categories" />
      <div className={classes.container}>
        <PageIntro file={data.file} data={data.markdownRemark} />
        {data.allMarkdownRemark.edges.map(({ node }) => {
          return (
            <div key={node.frontmatter.slug}>
              <ListHeading
                title={node.frontmatter.title}
                link={`/categories/${node.frontmatter.slug}`}
                linkTitle={intl.formatMessage({ id: "see-all" })}
              />
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export default injectIntl(CategoriesPage)

export const query = graphql`
  query($language: String) {
    file(relativePath: { eq: "undraw_searching.png" }) {
      childImageSharp {
        fixed(width: 600) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fields: { slug: { regex: "/categories/" }, lang: { eq: $language } }
      }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
          fields {
            slug
            lang
          }
        }
      }
    }
    markdownRemark(
      frontmatter: { lang: { eq: $language }, slug: { eq: "category" } }
    ) {
      frontmatter {
        slug
        title
        subtitle
      }
      html
    }
  }
`
